import React, { useContext } from "react";
import Hero from "../../Components/Hero/Hero";
// import Category from "../../Components/Category/Category"
import SuggestionCard from "../../Components/SuggestionCard/SuggestionCard";
import QuickSearch from "../../Components/Quick Search/QuickSearch ";
import StatsSection from "../../Components/Statesection/StatsSection";
import { MainContext } from "../../ContextApi/contextapi";
// import Explore from "../../Components/Explore/Explore"

function Home() {
  const images = [
    "/Assets/pexels-aibek-skakov-418917601-23259163.jpg",
    "/Assets/pexels-flodahm-541216.jpg",
  ];
  const { location } = useContext(MainContext);
  const placeholders = [
    "Beauty & Spa...",
    "Restaurants...",
    "Hotels...",
    "Hospitals...",
    "Gym...",
  ];
  return (
    <>
      <Hero images={images} placeholders={placeholders} />
      {/* <Category /> */}
      <div className="container">
        <div className="suggest-container">
          <h2>Top List of Near Business</h2>

          <SuggestionCard  lat={location.latitude} lng={location.longitude}  />
        </div>

        <QuickSearch />
        <StatsSection />
        {/* <Explore /> */}
      </div>
    </>
  );
}
export default Home;
