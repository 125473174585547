import React from "react";
import "./common.scss";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoPlanetSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";

function Common({ propertyData }) {
  const weekdays = propertyData?.weekday
    ? JSON.parse(propertyData.weekday)
    : null;
  return (
    <div className="info-container">
      <div className="category-list">
        <h4>More Info</h4>
        {propertyData?.website && (
          <div className="category-list__item">
            <Link
              to={propertyData.website}
              target="_blank"
              className="category-list__link flx-between"
            >
              <IoPlanetSharp />
              <span className="text">Website</span>
            </Link>
          </div>
        )}

        {propertyData?.link && (
          <div className="category-list__item">
            <Link
              to={propertyData.link}
              target="_blank"
              className="category-list__link flx-between"
            >
              <FaLocationDot />
              <span className="text">Location</span>
            </Link>
          </div>
        )}

        {propertyData?.phone && (
          <div className="category-list__item">
            <p className="category-list__link flx-between">
              <FaPhoneAlt />
              <span className="text">{propertyData.phone}</span>
            </p>
          </div>
        )}

        {propertyData?.phone && (
          <div className="category-list__item">
            <Link
              to={`https://wa.me/${propertyData.phone}`}
              target="_blank"
              className="category-list__link flx-between"
            >
              <IoLogoWhatsapp />
              <span className="text">{propertyData.phone}</span>
            </Link>
          </div>
        )}
      </div>
      {/* Open-Closed Time */}
      <div className="open-list">
        <h5>Open-Closed Time</h5>
        <ul>
          {weekdays ? (
            Object.entries(weekdays).map(([day, hours], index) => (
              <li key={index} className="open-list__item">
                <strong>{day}:</strong> {hours}
              </li>
            ))
          ) : (
            <li className="open-list__item">Opening hours not available</li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Common;
