import React, { useState } from "react";
import BusinessListing from "../../Components/BuisnessListing/BuisnessListing";
import "./bs.scss";

function BusinessPage() {
  const [mobileNumbers, setMobileNumbers] = useState("");

  const MobileChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      setMobileNumbers(value);
    }
  };
  const handleButtonClick = () => {
    const phoneRegex = /^[0-9]{10}$/;
    if (phoneRegex.test(mobileNumbers)) {
      console.log("ref to business component");
      setMobileNumbers("");
    } else {
      alert("Please enter a valid mobile number");
    }
  };

  return (
    <div className="container gap-top">
      <div className="business-list-container">
        <h1>
          List Your Business <span className="highlight">ONLINE</span> for{" "}
          <span className="highlight">FREE</span>
        </h1>
        <div className="mobile-input">
          <input
            type="text"
            value={mobileNumbers}
            placeholder="Enter Mobile No."
            onChange={MobileChange}
          />
          <button onClick={handleButtonClick}>Submit ➜</button>
        </div>
        <ul className="features">
          <h3>Simple Two Steps to List Your Business</h3>
          <li>First, fill your Mobile Number</li>
          <li>
            Add further details: name, address, business hours, and photos
          </li>
        </ul>
      </div>

      <BusinessListing />
    </div>
  );
}

export default BusinessPage;
