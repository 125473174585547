export let KEY_ACCESS_TOKEN = "access_token";
export const userId = "_id";
export function getItem(key) {
  return localStorage.getItem(key);
}
export function setItem(key, value) {
  localStorage.setItem(key, value);
}
export function removeItem(key) {
  localStorage.removeItem(key);
}

export function getId(key) {
  return localStorage.getItem(key);
}
export function setId(key, value) {
  localStorage.setItem(key, value);
}
export function removeId(key) {
  localStorage.removeItem(key);
}
