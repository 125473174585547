import React, { useState, useContext } from "react";
import "./buisness.scss";
// import { toast } from "react-toastify";
import { IoIosAddCircle } from "react-icons/io";
import ImageUpload from "../Image/ImageUpload";
import * as Yup from "yup";
import { useFormik } from "formik";
import { MainContext } from "../../ContextApi/contextapi";
import { countriesList } from "../../Data/countrylist";
import Weekdays from "../WeekDays/weekdays";
import { AddListing } from "../../Api/api";

function BusinessListing() {
  const { location } = useContext(MainContext);
  const [manualCoordinates, setManualCoordinates] = useState();
  const [images, setImages] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);

  // ******************************************************************************************************************
  const handleCordinates = (e) => {
    setManualCoordinates(e.target.checked);
    if (!manualCoordinates) {
      formik.setFieldValue("lng", location.longitude);
      formik.setFieldValue("lap", location.latitude);
    } else {
      formik.setFieldValue("lap", "");
      formik.setFieldValue("lng", "");
    }
    formik.values.location && console.log(formik.values.location);
  };

  const handleaddday = (e) => {
    setPopupOpen(true);
  };

  const handlecloseday = (e) => {
    setPopupOpen(false);
  };

  // ************************************************

  const validate = new Yup.object({
    name: Yup.string().required("name field required").min(3, "Too short"),
    phone_number: Yup.string()
      .required("phone number is required")
      .matches(/^\+?[1-9]\d{9,14}$/, "Incorrect Phone Number"),
    Website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("website filed is required"),
    Open: Yup.array().required("field must").min(1, "required field"),
    lng: Yup.string().required("longitude required"),
    lap: Yup.string().required("latitude required"),
    Address: Yup.object({
      pincode: Yup.string()
        .required("Pincode is required")
        .matches(/^[0-9]{6}$/, "Enter valid PIN CODE"),
      City: Yup.string()
        .required("City is required")
        .min(3, "City name Too short"),
      country: Yup.string().required("Select Country"),
      state: Yup.string().required("Select State"),
      Address1: Yup.string()
        .required("Address1 is required")
        .min(5, "Address is too short"),
    }),
  });

  const formik = new useFormik({
    initialValues: {
      phone_number: "",
      name: "",
      Open: [],
      lng: "",
      lap: "",
      types: [],
      Address: {
        pincode: "",
        City: "",
        Address2: "",
        Address1: "",
        country: "",
        state: "",
      },
      address: "",
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      formik.setFieldValue(
        "address",
        `${formik.values?.Address?.Address1}, ${formik.values?.Address?.Address2}, ${formik.values?.Address?.City},${formik.values?.Address?.state} ${formik.values?.Address?.pincode}, ${formik.values?.Address?.country}`
      );
      console.log(values, "images", images);
      AddListing(images, values);
      // resetForm();
      console.log(values);
    },
  });

  // console.log(formik);

  // ***********************************************************************************
  return (
    <div className="business-container">
      <form className="business-form" onSubmit={formik.handleSubmit}>
        <h2>Business Information</h2>
        <div className="form-group">
          <label htmlFor="name">Business Title</label>
          <input
            value={formik.values.name}
            type="text"
            id="name"
            name="name"
            className={
              formik.touched.name && formik.errors.name
                ? `common-input form-control is-invalid`
                : `common-input`
            }
            placeholder="Company Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="error-text">{formik.errors.name}</div>
          ) : null}
        </div>

        <div className="form-group-row">
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              name="phone_number"
              id="Phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone_number}
              className={
                formik.touched.phone_number && formik.errors.phone_number
                  ? `common-input form-control is-invalid`
                  : `common-input`
              }
              placeholder="+91"
            />
            {formik.touched.phone_number && formik.errors.phone_number ? (
              <div className="error-text">{formik.errors.phone_number}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="Website">Website</label>
            <input
              value={formik.values.Website}
              type="text"
              id="Website"
              name="Website"
              className={
                formik.touched.Website && formik.errors.Website
                  ? `common-input form-control is-invalid`
                  : `common-input`
              }
              placeholder="Website"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.Website && formik.errors.Website ? (
              <div className="error">{formik.errors.Website}</div>
            ) : null}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="category">Category</label>
          <input
            value={formik.values.types}
            type="text"
            id="category"
            name="types"
            className={
              formik.touched.Website && formik.errors.Website
                ? `common-input form-control is-invalid`
                : `common-input`
            }
            placeholder="Website"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <h2>Business Address</h2>
        <div className="form-group-row">
          <div className="form-group">
            <label htmlFor="addressLine1">Address line 1</label>
            <input
              type="text"
              id="addressLine1"
              placeholder="Address line 1"
              name="Address.Address1"
              className={
                formik.errors?.Address?.Address1 &&
                formik.touched?.Address?.Address1
                  ? `common-input form-control is-invalid`
                  : `common-input`
              }
              value={formik.values?.Address?.Address1}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors?.Address?.Address1 &&
            formik.touched?.Address?.Address1 ? (
              <div className="error">{formik.errors?.Address?.Address1}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="addressLine2">Address line 2</label>
            <input
              id="addressLine2"
              type="text"
              placeholder="Address line 2"
              name="Address.Address2"
              className="common-input"
              value={formik.values?.Address?.Address2}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              id="city"
              type="text"
              placeholder="City"
              name="Address.City"
              className={
                formik.errors?.Address?.City && formik.touched?.Address?.City
                  ? `common-input form-control is-invalid`
                  : `common-input`
              }
              value={formik.values?.Address?.City}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors?.Address?.City && formik.touched?.Address?.City ? (
              <div className="error">{formik.errors?.Address?.City}</div>
            ) : null}
          </div>
        </div>

        <div className="form-group-row">
          <div className="form-group">
            <label htmlFor="state">State</label>
            <input
              id="state"
              type="text"
              placeholder="State"
              name="Address.state"
              className={
                formik.errors?.Address?.state && formik.touched?.Address?.state
                  ? `common-input form-control is-invalid`
                  : `common-input`
              }
              value={formik.values?.Address?.state}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors?.Address?.state && formik.touched?.Address?.state ? (
              <div className="error">{formik.errors?.Address?.state}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="pinCode">PIN Code</label>
            <input
              id="pinCode"
              type="text"
              placeholder="PIN CODE"
              name="Address.pincode"
              className={
                formik.errors?.Address?.pincode &&
                formik.touched?.Address?.pincode
                  ? `common-input form-control is-invalid`
                  : `common-input`
              }
              value={formik.values?.Address?.pincode}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors?.Address?.pincode &&
            formik.touched?.Address?.pincode ? (
              <div className="error">{formik.errors?.Address?.pincode}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <select
              name="Address.country"
              id="countries"
              className={
                formik.errors?.Address?.country &&
                formik.touched?.Address?.country
                  ? `common-input form-control is-invalid`
                  : `common-input`
              }
              value={formik.values?.Address?.country}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            >
              <option></option>
              {countriesList.map((value, index) => {
                return (
                  <option value={value} key={index}>
                    {value}
                  </option>
                );
              })}
            </select>
            {formik.errors?.Address?.country &&
            formik.touched?.Address?.country ? (
              <div className="error-text">
                {formik.errors?.Address?.country}
              </div>
            ) : null}
          </div>
        </div>

        <label>Auto coordinates</label>
        <input type="checkbox" onChange={handleCordinates} />
        <div className="form-group-row">
          <div className="form-group">
            <label htmlFor="longitude">longitude</label>
            <input
              disabled={manualCoordinates}
              value={formik.values.lng}
              type="text"
              id="longitude"
              name="lng"
              className={
                formik.touched?.location?.longitude &&
                formik.errors?.location?.longitude
                  ? `common-input form-control is-invalid`
                  : `common-input`
              }
              placeholder="longitude"
              onInput={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ border: `1px solid var(--gray-200)` }}
            />
            {formik.touched?.location?.longitude &&
            formik.errors?.location?.longitude ? (
              <div className="error">{formik.errors?.location?.longitude}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="latitude">latitude</label>
            <input
              disabled={manualCoordinates}
              value={formik.values?.lap}
              type="text"
              id="latitude"
              name="lap"
              className={
                formik.touched?.location?.latitude &&
                formik.errors?.location?.latitude
                  ? `common-input form-control is-invalid`
                  : `common-input`
              }
              placeholder="latitude"
              onInput={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ border: `1px solid var(--gray-200)` }}
            />
            {formik.touched?.location?.latitude &&
            formik.errors?.location?.latitude ? (
              <div className="error">{formik.errors?.location?.latitude}</div>
            ) : null}
          </div>
        </div>
        <h2>Upload Image</h2>
        <div className="image-upload">
          <ImageUpload images={images} setImages={setImages} />
        </div>

        <h2>Business Hours</h2>
        <div
          type="button"
          onClick={handleaddday}
          className="add-new-day-button"
        >
          Add Hours <IoIosAddCircle />
        </div>

        {popupOpen && (
          <div className="modal-overlay">
            <Weekdays formik={formik} handlecloseday={handlecloseday} />
          </div>
        )}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default BusinessListing;
