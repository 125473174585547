import React from "react";
import StatsSection from "../../Components/Statesection/StatsSection";
import ContactDetail from "../../Components/ContactDetail/ContactDetail";

function Contact() {
  return (
    <div className="contact-page">
      <ContactDetail />
      <StatsSection />
    </div>
  );
}
export default Contact;
