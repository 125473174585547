import React from "react";
import "./epage.scss";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="error-page">
      <h1>404</h1>
      <p>Oops, nothing here...</p>
      <p>
        {" "}
        Uh oh, we can’t seem to find the page you’re looking for. Try going back
        to the previous page or contact us for more information.
      </p>
      <div className="actions">
        <Link to="/" className="btn-primary">
          Back to Homepage
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
