import React, { useContext, useEffect, useState } from "react";
import "./Hero.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MainContext } from "../../ContextApi/contextapi";

const Hero = ({ images, placeholders }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [inputData, setInputData] = useState("");
  const { location } = useContext(MainContext);
  const [radius, setRadius] = useState(50);
  const navigate = useNavigate();
  const BaseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const placeholderInterval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 2000);

    const imageInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(imageInterval);
      clearInterval(placeholderInterval);
    };
  }, [images, placeholders]);
  const createSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/--+/g, "-")
      .trim();
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };
  const handleSearchClick = async () => {
    if (inputData.trim()) {
      const slug = createSlug(inputData);
      if (!radius || isNaN(radius) || radius <= 0) {
        toast.warning("Please enter a valid radius in kilometers.");
        return;
      }

      try {
        const response = await axios.post(`${BaseUrl}/listing/textSearch`, {
          query: inputData,
          lat: location.latitude,
          lng: location.longitude,
          radiusKm: radius,
        });

        const data = response.data.data;
        const sortedData = data.sort((a ,b) =>{
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          const input  = inputData.toLowerCase();
          if(nameA.includes(input) && !nameB.includes(input)){
            return -1;
          }
          else if(!nameA.includes(input) && nameB.includes(input)){
            return 1;
          }
          return 0;
        });
        navigate(`/listing/${slug}`, { state: { resdata: sortedData } });
        setInputData("");
        setRadius("");
      } catch (error) {
        setInputData("");
        console.error("Error calling API:", error);
        toast.error("Network error");
      }
    } else {
      toast.warning("Please enter a search term.");
    }
  };

  return (
    <div className="hero-container">
      <div className="search-input">
        <h2 className="head-search">Search Business</h2>
        <input
          className="main-input"
          type="text"
          value={inputData}
          onKeyDown={handleKeyDown}
          onChange={(e) => setInputData(e.target.value)}
          placeholder={`Search for '${placeholders[placeholderIndex]}'`}
        />
        {/* <input
          className="radius-input"
          type="number"
          value={radius}
          onChange={(e) => setRadius(e.target.value)}
          placeholder="Radius(km)"
        /> */}
        <button
          className="btn-primary Search-submit"
          onClick={handleSearchClick}
        >
          SEARCH
        </button>
      </div>

      <div className="hero">
        <div
          className="carousel"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="carousel-item"
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          ))}
        </div>
        <div className="carousel-indicators">
          {images.map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === currentIndex ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hero;
