import React, { useState, useEffect } from "react";
import "./state.scss";

const StatsSection = () => {
  // Define state to store the current count for each stat
  const [happyCustomers, setHappyCustomers] = useState(0);
  const [totalListings, setTotalListings] = useState(0);
  const [allCategories, setAllCategories] = useState(0);
  const [wonAwards, setWonAwards] = useState(0);
  const animateCount = (targetValue, setState) => {
    let count = 0;
    const interval = setInterval(() => {
      count += Math.ceil(targetValue / 100);
      if (count >= targetValue) {
        clearInterval(interval);
        count = targetValue;
      }
      setState(count);
    }, 20);
  };
  useEffect(() => {
    animateCount(2048, setHappyCustomers);
    animateCount(4352, setTotalListings);
    animateCount(1750, setAllCategories);
    animateCount(1250, setWonAwards);
  }, []);

  return (
    <div className="stats-section">
      <h2>You can find what you want?</h2>
      <div className="stats-container">
        <div className="stat-item">
          <span className="stat-number">{happyCustomers}</span>
          <span className="stat-label">Happy Customers</span>
        </div>
        <div className="stat-item">
          <span className="stat-number">{totalListings}</span>
          <span className="stat-label">Total Listing</span>
        </div>
        <div className="stat-item">
          <span className="stat-number">{allCategories}</span>
          <span className="stat-label">All Categories</span>
        </div>
        <div className="stat-item">
          <span className="stat-number">{wonAwards}</span>
          <span className="stat-label">Won Awards</span>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
