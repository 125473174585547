import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import BusinessPage from "./Pages/Buisness-page/BuisnessPage"
import ErrorPage from "./Components/ErrorPage/ErrorPage"
import { getItem, KEY_ACCESS_TOKEN } from "./utils/localStorage";
import { useEffect } from "react";
import Home from "./Pages/Home/Home";
// import User from  "./Pages/User/User";
import Contact from "./Pages/Contact Page/Contact"

import Footer from "./Components/Footer/Footer"
import PropertyDetails from "./Components/Property-details/PropertyDetails";
import Property from "./Components/Property/Property";
import Navbar from "./Components/Navbar/Navbar";

const PrivateRoute = ({ children }) => {
  const token = getItem(KEY_ACCESS_TOKEN);
  if (!token) {
    return <Navigate to="*" replace />;
  }
  return children;
};
const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
};
function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:placeid" element={<PropertyDetails/>} />
        <Route path="/listing/:slug" element={<Property />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route
          path="/user-profile"
          element={
            <PrivateRoute>
              <User />
            </PrivateRoute>
          }
        /> */}
        <Route path="/Business-list" element={<BusinessPage />} />
      </Routes>
      <Footer />
    </div>
  );
}
export default App;
