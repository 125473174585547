import { useState } from "react";
import WeekdaysLinear from "../WeekDaysCircle/WeekDaysCircle.js";
import "./week.scss";
export default function Weekdays({ formik, handlecloseday }) {
  const [openingTime, setOpeningTime] = useState(false);
  // const [open, setOpen] = useState({});
  const [time, setTime] = useState({ From: "10:00", To: "18:00" });
  // const Week = [
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  // ];

  // const [day, setDay] = useState({
  //   Sunday: "closed",
  //   Monday: "closed",
  //   Tuesday: "closed",
  //   Wednesday: "closed",
  //   Thursday: "closed",
  //   Friday: "closed",
  //   Saturday: "closed",
  // });

  // const handleChange = (e) => {
  //   // if (e.target.checked) {
  //   //   setDay({...day, [e.target.name]:"open"});
  //   // } else {
  //   //   setDay({...day, [e.target.name]:"closed"});
  //   // }
  //   const { name, checked } = e.target;
  //   if (checked) {
  //     setDay({ ...day, [name]: "opened" });
  //   } else {
  //     setDay({ ...day, [name]: "closed" });
  //   }
  // };

  const handleTime = (e) => {
    const { name, value } = e.target;
    setTime({ ...time, [name]: value });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   let arr = [];
  //   for (let i in day) {
  //     if(openingTime){
  //           arr =day[i]=='opened'? [...arr, `${i} ${day[i]} 24 hours`]:[...arr, `${i} ${day[i]}`]
  //     }
  //     else{

  //       arr = day[i]=='opened'? [...arr, `${i} ${day[i]} From ${time.From} To ${time.To}`]: [...arr, `${i} ${day[i]}`];
  //     }

  //   }
  //   console.log(arr);

  //   formik.setFieldValue("Open", arr);

  // };
  return (
    <div div className="Week-container">
      <div className="timeInput">
        <label>24 hours</label>
        <input
          type="checkbox"
          onChange={(e) => setOpeningTime(e.target.checked)}
        />
        <input
          type="time"
          placeholder="From"
          name="From"
          onChange={handleTime}
          defaultValue="10:00"
          disabled={openingTime}
        />

        <input
          type="time"
          placeholder="to"
          name="To"
          onChange={handleTime}
          defaultValue="18:00"
          disabled={openingTime}
        />
        <WeekdaysLinear />

        <div>
          {/* {Week.map((day) => {
            return (
              <div className="d-flex ">
                <FormControlLabel
                  control={<Checkbox />}
                  label={day}
                  name={day}
                  onChange={handleChange}
                  key={day}
                />

                <input
                  className=""
                  id={day}
                  type="checkbox"
                  name={day}
                  autoComplete="off"
                  onChange={handleChange}
                />
                <label htmlFor={day}>{day.split("")[0]} </label>
              </div>
            );
          })} */}
        </div>
        <button type="submit" className="btnStyle" onClick={handlecloseday}>
          Set
        </button>
      </div>
    </div>
  );
}
