import React, { useEffect, useState, useRef, useCallback } from "react";
import { MdLocationOn } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./suggestion.scss";
import DoubleCircularSpinner from "../DoubleCircularSpinner/DoubleCircularSpinner";

const SuggestionCard = ({ lat, lng }) => {
  const [courseData, setCourseData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const BaseUrl = process.env.REACT_APP_API_URL;
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const fetchSuggestData = useCallback(
    async (page = 1) => {
      setLoading(true);

      try {
        const response = await axios.post(`${BaseUrl}/listing/suggestion`, {
          lat: lat,
          lng: lng,
          page: page,
        });
        setCourseData(response.data.data);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    },
    [BaseUrl, lat, lng]
  );

  useEffect(() => {
    if (lat && lng) {
      fetchSuggestData();
    }
  }, [fetchSuggestData, lat, lng]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (courseData.length > 0) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % courseData.length);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [courseData]);

  useEffect(() => {
    if (sliderRef.current) {
      if (currentIndex === courseData.length) {
        sliderRef.current.style.transition = "none";
        sliderRef.current.style.transform = `translateX(0px)`;
        setTimeout(() => {
          sliderRef.current.style.transition = "transform 0.5s ease-in-out";
        }, 50);
        setCurrentIndex(0);
      } else {
        sliderRef.current.style.transform = `translateX(-${
          currentIndex * 80
        }px)`;
      }
    }
  }, [currentIndex, courseData.length]);

  const truncateName = (name) => {
    const words = name.split(" ");
    if (words.length > 2) {
      return `${words[0]} ${words[1]}`;
    }
    return name;
  };

  const handleCardClick = async (id, link) => {
    const placeid = id;
    setLoading(true);
    try {
      const result = await axios.post(`${BaseUrl}/listing/placeDetails`, {
        place_id: id,
        link: link,
      });
      const detailedData = result.data.data;
      if (detailedData && detailedData.length > 0) {
        navigate(`/${placeid}`, { state: detailedData[0] });
      }
    } catch (err) {
      console.error("Error fetching detailed course data:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="carousel-container">
      {loading && (
        <div className="loading">
          <DoubleCircularSpinner />
        </div>
      )}
      <div
        className="suggestion-cards"
        ref={sliderRef}
        style={{ display: "flex", transition: "transform 0.5s ease-in-out" }}
      >
        {courseData.map((course, index) => (
          <div
            key={course.id}
            className="course-card"
            onClick={() => handleCardClick(course.place_id, course.link)}
          >
            <div className="card-content">
              <div className="course-title">{truncateName(course.name)}</div>

              <div className="rating">
                <span className="star filled">
                  {"★".repeat(Math.floor(course.rating)) +
                    "☆".repeat(5 - Math.floor(course.rating))}
                </span>
                <span className="rating-text">({course.rating})</span>
              </div>

              <div className="distance">
                <MdLocationOn className="location-icon" />
                <span>
                  {course.distance_km && !isNaN(course.distance_km)
                    ? course.distance_km.toFixed(2)
                    : "N/A"}{" "}
                  km
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuggestionCard;
