import React, { useState } from "react";
import "./image.scss";
import { FaCloudUploadAlt } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

const ImageUpload = () => {
  const [images, setImages] = useState([]);

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...selectedImages]);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  return (
    <div className="image-uploader">
      <label htmlFor="ImageUploadLabel" className="image-uploader__label">
        <span className="visually-hidden">
          {" "}
          <FaCloudUploadAlt />
          Upload Image
        </span>
      </label>

      <input
        type="file"
        className="image-uploader__input"
        id="ImageUploadLabel"
        multiple
        onChange={handleImageChange}
      />

      {images.length > 0 ? (
        <div className="uploaded-images">
          {images.map((image, index) => (
            <div className="uploaded-image" key={index}>
              <span
                type="button"
                className="uploaded-image__remove"
                onClick={() => handleRemoveImage(index)}
              >
                <RxCross2 />
              </span>
              <img
                src={URL.createObjectURL(image)}
                alt={`Uploaded file ${index + 1}`}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="upload-text">
          <span className="upload-text__icon">
            <i className="fas fa-cloud-upload-alt"></i>
          </span>
          <span className="upload-text__text">
            Drag & Drop files here or click to browse
          </span>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
