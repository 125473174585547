import { createContext, useState, useEffect } from "react";

export const MainContext = createContext();

const MainProvider = ({ children }) => {
  const [location, setLocation] = useState({
    latitude: 26.92207,
    longitude: 75.778885,
  });
  const [locationDenied, setLocationDenied] = useState(false);
  const [showLocationPrompt, setShowLocationPrompt] = useState(false);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setLocationDenied(false);
        },
        (error) => {
          console.error("Error getting location:", error);
          setLocationDenied(true);
          setLocation({ latitude: 26.92207, longitude: 75.778885 });
          setShowLocationPrompt(true);

          setTimeout(() => {
            setShowLocationPrompt(false);
          }, 5000);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLocation({ latitude: 26.92207, longitude: 75.778885 });
      setShowLocationPrompt(true);

      setTimeout(() => {
        setShowLocationPrompt(false);
      }, 5000);
    }
  }, []);

  const redirectToSettings = () => {
    if (window.location.protocol === "https:") {
      window.location.href = "chrome://settings/content/location";
    } else {
      alert(
        "Please enable location services in your browser settings for a better experience."
      );
    }
  };

  return (
    <MainContext.Provider value={{ location }}>
      {children}

      {showLocationPrompt && locationDenied && (
        <div className="location-prompt">
          <p>For a better experience, please enable location access.</p>
          <button onClick={redirectToSettings}>Go to Settings</button>
        </div>
      )}
    </MainContext.Provider>
  );
};

export default MainProvider;
