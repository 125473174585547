import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        {/* Logo and Description */}
        <div className="footer__col">
          <a href="/" className="footer__logo">
            <img
              src="/Assets/logo.webp"
              alt="logo"
              className="footer__logo-img"
            />
          </a>
          <div className="footer__col">
            <h4 className="footer__header">Follow Us On</h4>
            <div className="footer__socials">
              <a href="/" className="footer__socials-icon">
                <FaFacebook />
              </a>
              <a href="/" className="footer__socials-icon">
                <FaInstagram />
              </a>
              <a href="/" className="footer__socials-icon">
                <FaYoutube />
              </a>
              <a href="/" className="footer__socials-icon">
                <FaLinkedin />
              </a>
            </div>
            <p>&copy; 2025 My Map City </p>
          </div>
          {/* <p className="footer__description">
            Sed ut perspiciatis undmnis iste natus error sit amet voluptatem
            totam rem aperiam.
          </p> */}
          <div className="footer__contact">
            <span className="footer__contact-icon"></span>
          </div>
        </div>

        {/* Links */}
        {/* <div className="footer__col">
          <h4 className="footer__header">Resources</h4>
          <ul className="footer__links">
            <li><a href="/">SaaS Development</a></li>
            <li><a href="/">Our Products</a></li>
            <li><a href="/">User Flow</a></li>
            <li><a href="/">User Strategy</a></li>
          </ul>
        </div>
        <div className="footer__col">
          <h4 className="footer__header">Company</h4>
          <ul className="footer__links">
            <li><a href="/">About TailGrids</a></li>
            <li><a href="/">Contact & Support</a></li>
            <li><a href="/">Success History</a></li>
            <li><a href="/">Setting & Privacy</a></li>
          </ul>
        </div>
        <div className="footer__col">
          <h4 className="footer__header">Quick Links</h4>
          <ul className="footer__links">
            <li><a href="/">Premium Support</a></li>
            <li><a href="/">Our Services</a></li>
            <li><a href="/">Know Our Team</a></li>
            <li><a href="/">Download App</a></li>
          </ul>
        </div> */}
        {/* Social Links */}
        {/* <div className="footer__col">
          <h4 className="footer__header">Follow Us On</h4>
          <div className="footer__socials">
            <a href="/" className="footer__socials-icon"><FaFacebook /></a>
            <a href="/" className="footer__socials-icon"><FaInstagram /></a>
            <a href="/" className="footer__socials-icon"><FaYoutube /></a>
            <a href="/" className="footer__socials-icon"><FaLinkedin /></a>
          </div>
          <p>&copy; 2025 My Map City </p>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
