import React, { useState } from "react";
import "./propertydetail.scss";
import Common from "../Common Sidebar/Common";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { MdCancel } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import SuggestionCard from "../SuggestionCard/SuggestionCard";

function PropertyDetails() {
  const location = useLocation();
  const datad = location.state || {};

  const [selectedOption, setSelectedOption] = useState("");
  // const [rating, setRating] = useState(0);
  // const [comment, setComment] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [claimError, setClaimError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [document, setDocument] = useState(null);
  const BaseUrl = process.env.REACT_APP_API_URL;
  // Suggested Edit form states
  const [suggestedEditPopup, setSuggestedEditPopup] = useState(false);
  const [editName, setEditName] = useState("");
  const [editCategory, setEditCategory] = useState("");
  const [editWebsite, setEditWebsite] = useState("");
  const [editAddress, setEditAddress] = useState("");

  // const handleStarClick = (index) => {
  //   setRating(index + 1);
  // };

  // const handleSubmit = async (id) => {
  //   if (!comment.trim()) {
  //     toast.error("Comment cannot be empty!");
  //     return;
  //   }

  //   try {
  //     const res = await axios.post(
  //       `${BaseUrl}/listing/insertReviews`,
  //       {
  //         rating,
  //         reviews: comment,
  //         reference: id,
  //       }
  //     );

  //     const message = res.data.message;
  //     toast.success(message);
  //     setComment("");
  //     setRating(0);
  //   } catch (error) {
  //     console.log(error);
  //     setComment("");
  //     setRating(0);
  //     toast.error("Failed to submit review");
  //   }
  // };

  const handleClaimOwnership = async () => {
    if (!name || !email || !phoneNumber) {
      setClaimError("All fields are required!");
      return;
    }

    if (selectedOption === "document" && !document) {
      setClaimError("Please upload a document.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("propertyId", datad.place_id);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phoneNumber", phoneNumber);
      formData.append("claimOption", selectedOption);

      if (selectedOption === "document" && document) {
        formData.append("document", document);
      }

      const res = await axios.post(
        `${BaseUrl}/listing/claimOwnership`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.success) {
        toast.success("Ownership claimed successfully!");

        setName("");
        setEmail("");
        setPhoneNumber("");
        setDocument(null);
        setSelectedOption("");
        setShowPopup(false);
      } else {
        setClaimError("Failed to claim ownership. Please try again.");
      }
    } catch (error) {
      console.log(error);
      setClaimError("An error occurred while claiming ownership.");
    }
  };

  const handleSuggestedEditSubmit = async () => {
    const userToken =
      localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

    if (!userToken) {
      toast.error("User not authenticated. Please log in.");
      return;
    }

    if (!editName || !editCategory || !editWebsite || !editAddress) {
      toast.error("All fields are required!");
      return;
    }

    try {
      const response = await axios.post(
        `${BaseUrl}/listing/suggestEdit/${datad.place_id}`,
        {
          name: editName,
          category: editCategory,
          website: editWebsite,
          address: editAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Suggested edit submitted successfully!");
        setSuggestedEditPopup(false);
      } else {
        toast.error("Failed to submit suggested edit.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting suggested edit.");
    }
  };

  return (
    <section className="property-details">
      <ToastContainer />
      <div className="container">
        <div className="row">
          {/* <div className="property-details__thumb">
            <img
              src="/Assets/pexels-saviesa-home-1098995-2089698.jpg"
              alt="Property Thumbnail"
              className="cover-img"
            />
          </div> */}
          <h5 className="property-title">{datad.name || "Property Name"}</h5>
          <div className="property-info">
            <div className="ratings">
              Ratings:
              <h6 className="rate">
                {datad.rating}{" "}
                <span className="stars">
                  {"★".repeat(Math.floor(datad.rating)) +
                    "☆".repeat(5 - Math.floor(datad.rating))}
                </span>
              </h6>
            </div>
          </div>
          <p className="address">
            <span>Address: </span>
            {datad.address || "N/A"}
          </p>

          <p>
            {/* <span className="owner-claim" onClick={() => setShowPopup(true)}>
              Claim Ownership?
            </span> */}
            <span
              className="suggest-edit owner-claim"
              onClick={() => setSuggestedEditPopup(true)}
            >
              Suggested Edit
            </span>
          </p>

          {/* Suggested Edit Popup */}
          {suggestedEditPopup && (
            <div className="popup-overlay">
              <div className="popup">
                <h2>Suggested Edit</h2>

                <div className="popup-actions">
                  <label htmlFor="editName">Name</label>
                  <input
                    type="text"
                    id="editName"
                    placeholder="Enter property name"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                  />

                  <label htmlFor="editCategory">Category</label>
                  <input
                    type="text"
                    id="editCategory"
                    placeholder="Enter property category"
                    value={editCategory}
                    onChange={(e) => setEditCategory(e.target.value)}
                  />

                  <label htmlFor="editWebsite">Website</label>
                  <input
                    type="text"
                    id="editWebsite"
                    placeholder="Enter property website"
                    value={editWebsite}
                    onChange={(e) => setEditWebsite(e.target.value)}
                  />

                  <label htmlFor="editAddress">Address</label>
                  <input
                    type="text"
                    id="editAddress"
                    placeholder="Enter property address"
                    value={editAddress}
                    onChange={(e) => setEditAddress(e.target.value)}
                  />

                  <div
                    className="submit-button"
                    onClick={handleSuggestedEditSubmit}
                  >
                    Submit
                  </div>
                  <div
                    className="close"
                    onClick={() => setSuggestedEditPopup(false)}
                  >
                    <MdCancel />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Review section */}
          {/* <div className="reviews">
            <h6 className="reviews-title">Reviews</h6>
            {datad.reviews && datad.reviews.length > 0 ? (
              datad.reviews.map((review, index) => (
                <div className="review-item" key={index}>
                  <div className="review-header">
                    <div className="review-author">
                      <img
                        style={{ borderRadius: "50%" }}
                        src={
                          review.profile_photo_url ||
                          "https://placehold.co/50x50"
                        }
                        alt="Reviewer"
                      />
                      <div className="review-name">{review.author_name}</div>
                    </div>
                    <div className="review-date">
                      {review.relative_time_description}
                    </div>
                  </div>
                  <p className="review-rating">
                    {review.rating}{" "}
                    <span className="stars">
                      {"★".repeat(Math.floor(review.rating)) +
                        "☆".repeat(5 - Math.floor(review.rating))}
                    </span>
                  </p>
                  <p className="review-text">{review.text || "No comment."}</p>
                </div>
              ))
            ) : (
              <p>Be First For Comment</p>
            )}
            <hr />
          </div> */}

          <div className="suggesters">
            <h2>Suggested Properties</h2>
            <SuggestionCard lat={datad.lap} lng = {datad.lng} />
          </div>

          {/* Add comment and review section */}
          {/* <div className="star-rating-container">
            <h2>Add a Comment</h2>
            <div className="star-rating">
              {[...Array(5)].map((_, index) => (
                <span
                  key={index}
                  className={index < rating ? "star filled" : "star"}
                  onClick={() => handleStarClick(index)}
                >
                  ★
                </span>
              ))}
            </div>
            <textarea
              className="comment-input"
              placeholder="Add a comment..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            <button
              className="submit-button"
              onClick={() => handleSubmit(datad.place_id)}
            >
              Submit
            </button>
          </div> */}
        </div>

        {/* Sidebar */}
        <div className="sidebar">
          <Common propertyData={datad} />
        </div>
      </div>

      {/* Popup for Claim Ownership */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>Claim Ownership</h2>

            <div className="popup-actions">
              <label htmlFor="Name">Name</label>
              <input
                type="text"
                id="Name"
                placeholder="Enter your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <label htmlFor="BusinessEmail">Business E-mail</label>
              <input
                type="email"
                placeholder="Business E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <label htmlFor="PhoneNumber">Phone Number</label>
              <input
                type="text"
                placeholder="Enter mobile number"
                maxLength="10"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />

              {/* Radio buttons for options */}
              <div className="option-selector">
                <label>
                  <input
                    type="radio"
                    name="claimOption"
                    value="document"
                    checked={selectedOption === "document"}
                    onChange={() => setSelectedOption("document")}
                  />
                  Attach a document
                </label>

                <label>
                  <input
                    type="radio"
                    name="claimOption"
                    value="textInput"
                    checked={selectedOption === "textInput"}
                    onChange={() => setSelectedOption("textInput")}
                  />
                  Verification- Email
                </label>
              </div>

              {/* Conditional document input field */}
              {selectedOption === "document" && (
                <div className="document-input">
                  <input
                    type="file"
                    id="document"
                    onChange={(e) => setDocument(e.target.files[0])}
                  />
                </div>
              )}

              {/* Conditional text input field */}
              {selectedOption === "textInput" && (
                <div className="text-input">
                  <input type="text" id="description" placeholder="text" />
                  <div>.com</div>
                </div>
              )}
              {claimError && <p className="error-message">{claimError}</p>}
              <div className="claim" onClick={handleClaimOwnership}>
                Yes, Claim
              </div>
              <div className="close" onClick={() => setShowPopup(false)}>
                <MdCancel />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default PropertyDetails;
