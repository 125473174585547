import axios from "axios";
const URL = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
  baseURL: `${URL}`, // Set your API base URL here
  timeout: 10000, // Optional timeout setting
});
// Add a request interceptor to attach the bearer token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Getting token from storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Setingup the bearer token in the header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// const token = localStorage.getItem("token");
export const suggestEdit = async (id, values) => {
  try {
    const result = await axiosInstance.post(
      `/listing/suggestEdit/${id}`,
      values
    );
    return result;
  } catch (error) {
    return error;
  }
};

// Generating Random place_id
const array = [
  ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i)), // a-z
  ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)), // A-Z
  ...["_"],
  ...Array.from({ length: 10 }, (_, i) => String.fromCharCode(48 + i)), // 0-9
];
let str = "CHIJ";
for (let i = 0; i < 23; i++) {
  const random = Math.floor(Math.random() * 62);
  str = str + array[random];
}


// Add listing
export const AddListing = async (images, values) => {
  // args.place_id = str;
  console.log(images, values);
  try {
    const result = await axios.post(
      `${URL}/listing/insertweblisting`,
      { images, values },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};
