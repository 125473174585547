import React, { useState, useEffect, useContext, useRef } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
// import { TbLogout } from "react-icons/tb";
import "./navbar.scss";

import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { MainContext } from "../../ContextApi/contextapi";
// import { getItem, KEY_ACCESS_TOKEN, removeItem } from "../../utils/localStorage";
import Login from "../Login/Login";

const createSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/--+/g, "-")
    .trim();
};

function Navbar() {
  const navigate = useNavigate();
  const { location } = useContext(MainContext);
  const [inputData, setInputData] = useState("");
  const [radius, setRadius] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const BaseUrl = process.env.REACT_APP_API_URL;
  const locations = useLocation();
  const isHomePage = locations.pathname === "/";

  const menuRef = useRef(null);

  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };

  // const handleLoginClick = () => {
  //   setPopupOpen(true);
  //   document.body.style.overflow = "hidden";
  // };

  const closePopup = () => {
    setPopupOpen(false);
    document.body.style.overflow = "";
  };

  // const handleLoginSuccess = () => {
  //   setIsLoggedIn(true);
  //   closePopup();
  // };

  // const handleLogout = () => {
  //   removeItem(KEY_ACCESS_TOKEN);
  //   setIsLoggedIn(false);
  //   navigate("/");
  //   setMenuOpen(false);
  // };

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight * 0.15) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  // const handleRadiusChange = (e) => {
  //   setRadius(e.target.value);
  // };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };
  const handleSearchClick = async () => {
    const searchRadius = radius.trim() ? parseFloat(radius) : 50;
    if (inputData.trim()) {
      const slug = createSlug(inputData);

      try {
        const response = await axios.post(`${BaseUrl}/listing/textSearch`, {
          query: inputData,
          lat: location.latitude,
          lng: location.longitude,
          radiusKm: searchRadius,
        });

        const data = response.data.data;
        const sortedData = data.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          const input = inputData.toLowerCase();

          if (nameA.includes(input) && !nameB.includes(input)) {
            return -1;
          } else if (!nameA.includes(input) && nameB.includes(input)) {
            return 1;
          }
          return 0;
        });

        navigate(`/listing/${slug}`, { state: { resdata: sortedData } });

        setInputData("");
        setRadius("");
      } catch (error) {
        setInputData("");
        setRadius("");
        console.error("Error calling API:", error);
        toast.error("Error fetching search results.");
      }
    } else {
      toast.warning("Please enter a search term.");
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuOpen(false);
      }

      if (e.target.classList.contains("modal-overlay")) {
        closePopup();
      }
    };

    // const token = getItem(KEY_ACCESS_TOKEN);
    // if (token) {
    //   // setIsLoggedIn(true);
    // }

    if (popupOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    if (isHomePage) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [locations.pathname, popupOpen, isHomePage]);

  const closeMenuOnClick = () => {
    setMenuOpen(false);
  };
  return (
    <>
      <div className={`Navbar ${scrolled && isHomePage ? "scrolled" : ""}`}>
        <ToastContainer />
        <div className="container">
          <div className="left-nav">
            <Link to="/">
              <img src="/Assets/logo.webp" alt="Logo" />
            </Link>
            {(scrolled && isHomePage) || !isHomePage ? (
              <div className="search-input-s">
                <input
                  type="text"
                  placeholder="Search..."
                  value={inputData}
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                />
                <p className="search-icon" onClick={handleSearchClick}>
                  <FaSearch />
                </p>
              </div>
            ) : null}
          </div>

          <div className="right-nav">
            <ul className="flex">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              {/* <li>
               <Link to="/Business-list">Add Business</Link>
              </li> */}
            </ul>

            <div className="toggle-btn" onClick={handleToggle}>
              <RxHamburgerMenu />
            </div>

            {/* {!isLoggedIn ? (
              <div className="btn-login btn-primary" onClick={handleLoginClick}>
                Login
              </div>
            ) : (
              <div className="logged-in-message">
                <p onClick={handleLogout}>
                  <TbLogout />
                </p>
                <Link to="/user-profile">
                  <img
                    className="profile-img"
                    src="/Assets/hkim_banquethall.png"
                    alt="Profile"
                  />
                </Link>
              </div>
            )} */}
          </div>
        </div>

        <div className="mobile-search">
          <input
            type="text"
            placeholder="Search here..."
            value={inputData}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
          />
          {/* <input
            type="number"
            placeholder="Radius (km)"
            value={radius}
            onChange={handleRadiusChange}  // Added handler for radius input
          /> */}
          <div onClick={handleSearchClick}>
            <FaSearch />
          </div>
        </div>
      </div>

      {popupOpen && (
        <div className="modal-overlay">
          <Login />
        </div>
      )}

      {menuOpen && (
        <div ref={menuRef} className={`right-popup ${menuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/" onClick={closeMenuOnClick}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={closeMenuOnClick}>
                Contact Us
              </Link>
            </li>
            {/* <li>
              <Link to="/Business-list" onClick={closeMenuOnClick}>
                Add Business
              </Link>
            </li>
            <li onClick={handleLogout}>Log out</li> */}
          </ul>
        </div>
      )}
    </>
  );
}

export default Navbar;
