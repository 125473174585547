import React, { useState, useEffect } from "react";
import "./login.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import { KEY_ACCESS_TOKEN, setItem } from "../../utils/localStorage";
import axios from "axios";

const Login = ({ onLoginSuccess }) => {
  const navigate = useNavigate();
  const BaseUrl = process.env.REACT_APP_API_URL;
  const [otpSent, setOtpSent] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(30);
  useEffect(() => {
    let countdown;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
    }

    return () => clearInterval(countdown);
  }, [otpSent, timer]);

  const resetTimer = () => setTimer(30);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    const phoneRegex = /^[0-9]{10}$/;

    if (!phoneRegex.test(mobileNumber)) {
      toast.error("Please enter a valid mobile number.");
      return;
    }
    try {
      const response = await axios.post(`${BaseUrl}/sendOtp`, {
        Mobile: mobileNumber,
      });
      console.log(response);
      const datas = response.data;
      toast.success(datas.otp);
      setOtpSent(true);
      resetTimer();
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error(
        error.response?.data?.message || "Failed to send OTP. Please try again."
      );
    }
  };
  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      setMobileNumber(value);
    }
    if (otpSent) setOtpSent(false);
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 1);
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      e.target
        .closest(".otp-box")
        .nextElementSibling?.querySelector("input")
        ?.focus();
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (
      (e.key === "Backspace" || e.key === "Delete") &&
      otp[index].length === 0 &&
      index > 0
    ) {
      e.target
        .closest(".otp-box")
        .previousElementSibling?.querySelector("input")
        ?.focus();
    }
  };

  const verifyOtp = async (enteredOtp) => {
    console.log(enteredOtp);

    try {
      const res = await axios.post(`${BaseUrl}/verifyOtp`, {
        Mobile: mobileNumber,
        otp: enteredOtp,
      });
      const data = res.data;

      setItem(KEY_ACCESS_TOKEN, data.token);

      toast.success("Logged in successfully");
      setTimeout(() => {
        onLoginSuccess();
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Invalid OTP. Please try again.");
    }
  };

  return (
    <div className="container-login">
      <ToastContainer />
      <div className="glass-container">
        <img src="/Assets/logo.webp" alt="My map city Logo" />
        {!otpSent ? (
          <>
            <h1>LOGIN</h1>
            <p>Enter Mobile Number to receive the OTP</p>
            <div className="input-field">
              <input
                type="text"
                placeholder="Enter mobile number"
                value={mobileNumber}
                onChange={handleMobileChange}
                maxLength="10"
                aria-label="Mobile number"
              />
            </div>

            <button onClick={handleSendOtp} className="btn-primary s-btn">
              Send OTP
            </button>
          </>
        ) : (
          <>
            <h1>Verify OTP</h1>
            <p>Enter the OTP sent to your mobile</p>
            <div className="otp-container">
              {otp.map((digit, index) => (
                <div key={index} className="otp-box">
                  <input
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleOtpKeyDown(e, index)}
                    aria-label={`OTP digit ${index + 1}`}
                  />
                </div>
              ))}
            </div>
            <button
              onClick={() => verifyOtp(otp.join(""))}
              className="btn-primary s-btn"
            >
              Verify OTP
            </button>
            <div className="resend-container">
              {timer > 0 ? (
                <p>Resend OTP in {timer}s</p>
              ) : (
                <button onClick={handleSendOtp} className="resend-btn">
                  Resend OTP
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
