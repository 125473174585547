import React, { useState } from "react";
import "./WeekDaysCircle.css";

const WeekdaysLinear = () => {
  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];
  const [checkedDays, setCheckedDays] = useState(
    Array(weekdays.length).fill(false)
  );

  const handleDayClick = (index) => {
    const newCheckedDays = [...checkedDays];
    newCheckedDays[index] = !newCheckedDays[index]; // Toggle the checkbox state
    setCheckedDays(newCheckedDays);
  };
  return (
    <div className="weekdays-container">
      {weekdays.map((day, index) => (
        <div
          key={index}
          className={`day ${checkedDays[index] ? "active" : ""}`}
          onClick={() => handleDayClick(index)}
        >
          {day}
          <input
            type="checkbox"
            checked={checkedDays[index]}
            readOnly
            style={{ display: "none" }}
          />
        </div>
      ))}
    </div>
  );
};
export default WeekdaysLinear;
