import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import MainProvider from "./ContextApi/contextapi";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MainProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </MainProvider>
);
