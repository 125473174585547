import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "./property.scss";
import { FaLocationDot, FaWhatsapp } from "react-icons/fa6";
import { FaPhoneAlt, FaRegBookmark } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { GiPathDistance } from "react-icons/gi";
import { TbChartBarPopular } from "react-icons/tb";
import DoubleCircularSpinner from "../DoubleCircularSpinner/DoubleCircularSpinner";

const Property = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const data = useMemo(() => {
    return location.state ? location.state.resdata : [];
  }, [location.state]);

  const [visibleItems, setVisibleItems] = useState(10);
  const [filteredData, setFilteredData] = useState(data);
  const [sortOption, setSortOption] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const observerRef = useRef(null);
  const triggerRef = useRef(null);
  const BaseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (location.state?.resdata) {
      setFilteredData(location.state.resdata);
      setIsLoading(true);
    }
  }, [location.state]);

  const loadMoreItems = useCallback(() => {
    setVisibleItems((prev) => Math.min(prev + 6, filteredData.length));
  }, [filteredData.length]);

  const sortedData = useMemo(() => {
    let sorted = [...filteredData];

    if (sortOption === "popular") {
      sorted.sort((a, b) => b.user_ratings_total - a.user_ratings_total);
    } else if (sortOption === "topRated") {
      sorted.sort((a, b) => {
        if (b.rating === a.rating) {
          return b.user_ratings_total - a.user_ratings_total;
        }
        return b.rating - a.rating;
      });
    } else if (sortOption === "distance") {
      sorted.sort(
        (a, b) => (a.distance_km || Infinity) - (b.distance_km || Infinity)
      );
    } else if (sortOption === "trending") {
      sorted.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    return sorted;
  }, [filteredData, sortOption]);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadMoreItems();
        }
      },
      { threshold: 1.0 }
    );

    if (triggerRef.current) {
      observer.observe(triggerRef.current);
    }

    observerRef.current = observer;

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [loadMoreItems]);

  const clickedItem = async (id, link) => {
    const placeid = id;
    try {
      const result = await axios.post(`${BaseUrl}/listing/placeDetails`, {
        place_id: id,
        link,
      });
      const datad = result.data.data;

      if (datad && datad.length > 0) {
        navigate(`/${placeid}`, { state: datad[0] });
      }
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  const filterByStarRating = useCallback(
    (stars) => {
      const filtered = stars
        ? data.filter((item) => item.rating >= stars)
        : data;
      setFilteredData(filtered);
      setVisibleItems(10);
    },
    [data]
  );

  useEffect(() => {
    if (filteredData.length > 0) {
      setIsLoading(false);
    }
  }, [filteredData]);

  return (
    <section className="property gap-top" key={JSON.stringify(filteredData)}>
      <div className="more-info">
        <div className="quick-filters">
          <h3>Quick Filters</h3>
          <div className="filter-buttons">
            <button
              className={`filter-button ${
                sortOption === "trending" ? "selected" : ""
              }`}
              onClick={() => setSortOption("trending")}
            >
              <span className="filter-icon">🔥</span> Trending
            </button>
            <button
              className={`filter-button ${
                sortOption === "topRated" ? "selected" : ""
              }`}
              onClick={() => setSortOption("topRated")}
            >
              <span className="filter-icon">⭐</span> Top Rated
            </button>
            <button
              className={`sort-button ${
                sortOption === "popular" ? "selected" : ""
              }`}
              onClick={() => setSortOption("popular")}
            >
              <span className="filter-icon popular">
                <TbChartBarPopular />
              </span>
              Popular
            </button>
            <button
              className={`sort-button ${
                sortOption === "distance" ? "selected" : ""
              }`}
              onClick={() => setSortOption("distance")}
            >
              <span className="filter-icon location">
                <GiPathDistance />
              </span>{" "}
              Distance
            </button>
          </div>

          <h3>Star Rating</h3>
          <div className="star-rating-buttons">
            {[5, 4, 3, 2, 1].map((stars) => (
              <button
                key={stars}
                className="star-button"
                onClick={() => filterByStarRating(stars)}
              >
                {stars} Star
              </button>
            ))}
          </div>
        </div>

        {/* <div className="info">
          <h2>
            List of Top <span className="data"></span>
          </h2>
          <p>We can provide more information or contact Number to you - </p>
          <form>
            <input type="text" placeholder="Name" required />
            <input type="tel" placeholder="Mobile Number" required />
            <input type="email" placeholder="Email Address" required />
            <button type="submit">Send Details</button>
          </form>
        </div> */}
      </div>

      <div className="property-detail">
        {/* Show spinner if data is loading */}
        {isLoading ? (
          <DoubleCircularSpinner />
        ) : (
          <>
            {sortedData.slice(0, visibleItems).map((item, index) => (
              <div key={item.place_id}>
                <div
                  className="property-div"
                  onClick={() => clickedItem(item.place_id, item.link)}
                >
                  <div className="img-div">
                    <img
                      src={item.image || "/Assets/logo.webp"}
                      alt={item.name}
                    />
                  </div>
                  <p className="eye-icon">
                    <FaRegBookmark />
                  </p>
                  <div className="childs-div">
                    <h3 className="title-div">{item.name}</h3>

                    <div className="urates">
                      <h6 className="rate">
                        <p>
                          {item.rating}{" "}
                          <span className="stars">
                            {"★".repeat(Math.floor(item.rating)) +
                              "☆".repeat(5 - Math.floor(item.rating))}
                          </span>
                        </p>

                        <p className="time">{item.open_now}..</p>
                      </h6>
                      <p className="rated">
                        User Ratings: {item.user_ratings_total}
                      </p>
                    </div>
                    <p className="address">
                      <FaLocationDot /> {item.address || "N/A"}
                    </p>
                    <p className="faraway">
                      {item.distance_km && !isNaN(item.distance_km)
                        ? item.distance_km.toFixed(2)
                        : "N/A"}{" "}
                      km
                    </p>
                    <div className="linked">
                      <div className="mob-button">
                        <span className="mob-icon">
                          <FaPhoneAlt />
                        </span>
                        {item.phone || "N/A"}
                      </div>
                      <div className="mob-button">
                        <span className="mob-icon">
                          <FaWhatsapp />
                        </span>{" "}
                        Whatsapp
                      </div>
                      <div className="location">
                        <span className="mob-icon">
                          <FaLocationDot />
                        </span>{" "}
                        Location
                      </div>
                    </div>
                  </div>
                </div>

                {/* Google Ad after every two items */}
                {/* {(index + 1) % 3 === 0 && (
                  <div className="google-ad">
                    <iframe
                      width="1280"
                      height="720"
                      src="https://www.youtube.com/embed/kTJwOvy0ZbQ"
                      title="2nd Test Day 2 | Highlights | Australia Tour Of Sri Lanka | 7th February 2025"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                )} */}
              </div>
            ))}
          </>
        )}

        <div
          ref={triggerRef}
          style={{ height: "2px", backgroundColor: "transparent" }}
        ></div>
      </div>
    </section>
  );
};

export default Property;
