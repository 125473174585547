import React from "react";
import "./quick.scss";
import { CiLocationOn } from "react-icons/ci";
import { FaUsers } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";

function QuickSearch() {
  const steps = [
    {
      id: 1,
      icon: <FaMobileAlt />,
      title: "Choose a Category",
      description:
        "Search different categories in quick search on your device with Us",
    },
    {
      id: 2,
      icon: <CiLocationOn />,
      title: "Find Location",
      description:
        "Choose location to where you want to search and Explore More ",
    },
    {
      id: 3,
      icon: <FaUsers />,
      title: "Contact a Few Owners",
      description:
        "Connect to Other Business Services to Increase your Business Growth",
    },
  ];

  return (
    <div className="quick-search">
      <h2>Quick and Easy Search</h2>
      <p>
        "Quick and easy search helps you discover relevant results instantly,
        saving time and effort while providing accurate, tailored options."
      </p>
      <div className="steps-container">
        {steps.map((step) => (
          <div key={step.id} className="step">
            <div className="step-icon">{step.icon}</div>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
export default QuickSearch;
